export const friquet = (program, classProgram) => {
  if (program) {
    switch (true) {
      case program.value === "פעילות סוכות": {
        return [{ value: 0, label: "חד פעמי" }];
      }
      case program.value === "אנגלית": {
        return [
          { value: 1, label: "שכבת ג׳" },
          { value: 2, label: "שכבת ד׳" },
          { value: 3, label: "שכבת ה׳" },
          { value: 4, label: "שכבת ו׳" },
        ];
      }
      case program.value === "היפ-הופ": {
        return [
          { value: 1, label: "רגיל" },
          { value: 2, label: "תחרותי" },
          { value: 3, label: "נשים" },
        ];
      }
      case program.value === "סטודיו מיתרים": {
        if (
          classProgram.label === "גיטרה פרטני" ||
          classProgram.label === "אורגנית פרטני"
        ) {
          return [
            { value: 1, label: "30 דק׳" },
            { value: 2, label: "45 דק׳" },
          ];
        } else if (classProgram.label === "פרטני פיתוח קול מלווה בפסנתר") {
          return [{ value: 1, label: "30 דק׳" }];
        } else {
          return [{ value: 1, label: "45 דק׳" }];
        }
      }
      case program.value === "ג׳ודו" || program.value === "כדורעף": {
        return [
          { value: 1, label: "פעם בשבוע" },
          { value: 2, label: "פעמיים בשבוע" },
          { value: 3, label: "45 דק׳" },
        ];
      }

      case program.value === "טאקוונדו": {
        return [{ value: 1, label: "פעם בשבוע" }];
      }
      case program.value === "כדור יד" ||
        program.value === "טריקינג" ||
        program.value === "קרב מגע לילדים":
        return [{ value: 1, label: "פעם בשבוע" }];
      case program.value === "מצויינות בספורט":
        return [{ value: 1, label: "פעמיים בשבוע" }];
      case program.value.includes("מצוינות בספורט עולים"):
        return [{ value: 1, label: "חד פעמי" }];
      case program.value === "מצויינות קרקע ומכשירים":
        return [{ value: 1, label: "חודשי" }];
      //only twice cases!
      case program.value === "צהרון 2023-2024":
        return [{ value: 1, label: "יומי" }];
      case program.value === "תשלומי עמותה" ||
        program.value === "דמי רישום מצויינות בספורט":
        return [{ value: 1, label: "חד פעמי" }];
      case program.value === "צהרון קיץ":
        return [{ value: 1, label: "חד פעמי" }];
      default:
        return [{ value: 1, label: "פעם בשבוע" }];
    }
  } else {
    return "";
  }
};
