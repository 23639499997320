export const START = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const SUCCESS = "/success";
export const FAILER = "/fail";
export const Ap = "/ap/:id";
export const Tricking = "/tricking/:id/:email";
export const Taekwondo = "/taekwondo/:id/:email";
export const TaekwondoLate = "/taekwondoLate/:id/:email";
export const TrickingLate = "/trickingLate/:id/:email";
export const Links = "/link/:price";
